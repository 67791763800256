<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14V7.00001C12 6.73479 12.1054 6.48044 12.2929 6.2929C12.4804 6.10537 12.7348 6.00001 13 6.00001C13.2652 6.00001 13.5196 6.10537 13.7071 6.2929C13.8947 6.48044 14 6.73479 14 7.00001V14C14 14.2652 13.8947 14.5196 13.7071 14.7071C13.5196 14.8947 13.2652 15 13 15C12.7348 15 12.4804 14.8947 12.2929 14.7071C12.1054 14.5196 12 14.2652 12 14ZM26 8.44376V17.5563C26.0008 17.819 25.9495 18.0793 25.8488 18.322C25.7482 18.5647 25.6003 18.785 25.4138 18.97L18.97 25.4138C18.785 25.6003 18.5647 25.7482 18.322 25.8488C18.0793 25.9495 17.819 26.0008 17.5563 26H8.44376C8.18102 26.0008 7.92073 25.9495 7.67803 25.8488C7.43533 25.7482 7.21505 25.6003 7.03001 25.4138L0.58626 18.97C0.399735 18.785 0.251852 18.5647 0.151209 18.322C0.0505663 18.0793 -0.000829299 17.819 1.01186e-05 17.5563V8.44376C-0.000829299 8.18102 0.0505663 7.92073 0.151209 7.67803C0.251852 7.43533 0.399735 7.21505 0.58626 7.03001L7.03001 0.58626C7.21505 0.399735 7.43533 0.251852 7.67803 0.151209C7.92073 0.0505663 8.18102 -0.000829299 8.44376 1.01186e-05H17.5563C17.819 -0.000829299 18.0793 0.0505663 18.322 0.151209C18.5647 0.251852 18.785 0.399735 18.97 0.58626L25.4138 7.03001C25.6003 7.21505 25.7482 7.43533 25.8488 7.67803C25.9495 7.92073 26.0008 8.18102 26 8.44376ZM24 8.44376L17.5563 2.00001H8.44376L2.00001 8.44376V17.5563L8.44376 24H17.5563L24 17.5563V8.44376ZM13 17C12.7033 17 12.4133 17.088 12.1667 17.2528C11.92 17.4176 11.7277 17.6519 11.6142 17.926C11.5007 18.2001 11.471 18.5017 11.5288 18.7926C11.5867 19.0836 11.7296 19.3509 11.9394 19.5607C12.1491 19.7704 12.4164 19.9133 12.7074 19.9712C12.9983 20.0291 13.2999 19.9994 13.574 19.8858C13.8481 19.7723 14.0824 19.58 14.2472 19.3334C14.412 19.0867 14.5 18.7967 14.5 18.5C14.5 18.1022 14.342 17.7207 14.0607 17.4394C13.7794 17.158 13.3978 17 13 17Z"
      fill="rgb(210 68 50)"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningSvg",
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
