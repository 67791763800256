<template>
  <div class="header-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="48"
      viewBox="0 0 107 48"
      fill="none"
    >
      <g clip-path="url(#clip0_560_2575)">
        <path
          d="M102.208 29.2859H94.5589L90.7363 36.407L94.5589 43.5281H102.208L106.034 36.407L102.208 29.2859Z"
          stroke="#14D8B5"
          stroke-width="1.67219"
          stroke-miterlimit="10"
        />
        <path
          d="M98.3847 40.8716C100.679 40.8716 102.538 38.8727 102.538 36.407C102.538 33.9413 100.679 31.9424 98.3847 31.9424C96.0906 31.9424 94.231 33.9413 94.231 36.407C94.231 38.8727 96.0906 40.8716 98.3847 40.8716Z"
          stroke="#14D8B5"
          stroke-width="1.67219"
          stroke-miterlimit="10"
        />
        <path
          d="M21.8252 18.0597C20.7763 18.4826 19.6609 18.6828 18.541 18.6492C14.7318 18.6492 12.3071 16.1329 12.3071 12.1787C12.3071 8.4762 14.6649 5.44587 19.0461 5.44587C19.9978 5.44183 20.9433 5.61202 21.842 5.94913L21.2734 8.87162C20.6228 8.58415 19.9231 8.44697 19.22 8.46901C17.2836 8.46901 16.0395 9.94643 16.0562 12.0206C16.0562 14.3463 17.5076 15.5829 19.2802 15.5829C20.0031 15.5955 20.7213 15.455 21.3938 15.1695L21.8252 18.0597Z"
          fill="#14D8B5"
        />
        <path
          d="M23.832 0H27.5108V7.23254H27.561C27.94 6.6852 28.4308 6.23876 28.9957 5.92766C29.5832 5.59443 30.2378 5.42159 30.902 5.4244C33.2431 5.4244 35.0323 7.16423 35.0323 10.9854V18.3761H31.3535V11.4168C31.3535 9.74882 30.8184 8.6093 29.4606 8.6093C29.0572 8.60541 28.6628 8.73753 28.3329 8.98706C28.0029 9.2366 27.754 9.591 27.6212 10.0005C27.5322 10.2777 27.4914 10.5701 27.5008 10.8632V18.3761H23.832V0Z"
          fill="#14D8B5"
        />
        <path
          d="M47.6775 9.75608C47.6775 8.178 47.6307 6.84437 47.5806 5.70845H50.6775L50.8346 7.42312H50.9216C51.304 6.78929 51.8322 6.2723 52.455 5.92216C53.0778 5.57202 53.7742 5.40059 54.4767 5.42446C56.0084 5.42446 57.1956 6.26562 57.704 7.58128H57.7542C58.1543 6.95396 58.6756 6.42675 59.2825 6.03557C59.9598 5.60767 60.7345 5.39108 61.5199 5.41009C63.7707 5.41009 65.4897 7.12116 65.4897 10.9064V18.3618H61.9146V11.4959C61.9146 9.66261 61.3627 8.59499 60.1788 8.59499C59.801 8.60518 59.4359 8.74347 59.1355 8.99009C58.8352 9.2367 58.6151 9.57903 58.5066 9.96816C58.4159 10.2922 58.3708 10.6291 58.3729 10.9675V18.3762H54.7944V11.2766C54.7944 9.68059 54.266 8.59499 53.0988 8.59499C52.7113 8.61163 52.3381 8.75668 52.0304 9.01024C51.7226 9.2638 51.4954 9.61347 51.3798 10.0113C51.2804 10.3321 51.234 10.6691 51.2427 11.007V18.3762H47.6775V9.75608Z"
          fill="#14D8B5"
        />
        <path
          d="M72.1582 2.1927C72.1582 3.27111 71.4091 4.159 70.1884 4.159C69.9406 4.17545 69.6923 4.1365 69.4592 4.04461C69.2261 3.95271 69.0133 3.80987 68.8342 3.62509C68.655 3.44032 68.5135 3.21763 68.4185 2.97108C68.3234 2.72454 68.277 2.4595 68.2821 2.1927C68.2821 1.06396 69.048 0.219207 70.2219 0.219207C70.4723 0.199663 70.7238 0.236478 70.9603 0.327295C71.1968 0.418113 71.413 0.560938 71.5952 0.746651C71.7775 0.932365 71.9216 1.15688 72.0186 1.40587C72.1155 1.65486 72.1631 1.92284 72.1582 2.1927ZM68.3858 18.3688V5.70831H72.0646V18.376L68.3858 18.3688Z"
          fill="#14D8B5"
        />
        <path
          d="M83.8268 18.0597C82.779 18.4827 81.6647 18.6829 80.5459 18.6492C76.7367 18.6492 74.312 16.1329 74.312 12.1787C74.312 8.4762 76.6531 5.44587 81.0476 5.44587C82.0005 5.44184 82.947 5.61202 83.8468 5.94913L83.2749 8.87162C82.6256 8.5835 81.927 8.44629 81.2248 8.46901C79.2884 8.46901 78.0443 9.94643 78.0577 12.0206C78.0577 14.3463 79.5125 15.5829 81.285 15.5829C82.0069 15.5958 82.7241 15.4554 83.3953 15.1695L83.8268 18.0597Z"
          fill="#14D8B5"
        />
        <path
          d="M92.6461 18.3761L92.4287 17.1179H92.3518C91.5826 18.128 90.382 18.6636 88.9874 18.6636C86.5861 18.6636 85.1748 16.8088 85.1748 14.785C85.1748 11.485 87.9239 9.92135 92.0943 9.92854V9.76677C92.0943 9.09097 91.7599 8.13119 89.9338 8.13478C88.7947 8.15145 87.6778 8.47662 86.6865 9.08019L86.0176 6.5639C87.4324 5.77935 89.0078 5.38879 90.5994 5.42798C94.5223 5.42798 95.7631 7.9191 95.7631 10.8955V15.2954C95.7459 16.3213 95.8086 17.347 95.9504 18.3617L92.6461 18.3761ZM92.2013 12.3873C90.275 12.3873 88.7834 12.8726 88.7834 14.3824C88.7834 15.4033 89.4054 15.8993 90.2382 15.8993C90.6559 15.899 91.0632 15.7586 91.4031 15.4976C91.743 15.2366 91.9987 14.8681 92.1345 14.4435C92.1856 14.2238 92.2082 13.9976 92.2013 13.7713V12.3873Z"
          fill="#14D8B5"
        />
        <path d="M98.6523 0H102.331V18.3761H98.6523V0Z" fill="#14D8B5" />
        <path
          d="M0 40.4584C0.0501657 39.6208 0.0969847 38.1111 0.0969847 36.6732V22.0715H3.7758V29.2897H3.82262C4.5216 28.2113 5.74564 27.4923 7.38104 27.4923C10.2104 27.4923 12.2705 30.0086 12.2438 33.8909C12.2438 38.4526 9.55154 40.7208 6.85263 40.7208C5.47474 40.7208 4.14703 40.1744 3.32765 38.6718H3.27749L3.09689 40.4584H0ZM3.76577 35.0664C3.76318 35.2994 3.78449 35.5321 3.82931 35.7601C3.94113 36.2872 4.21469 36.7586 4.60597 37.0983C4.99725 37.438 5.48349 37.6263 5.98643 37.633C7.54157 37.633 8.51144 36.3317 8.51144 34.0383C8.51144 32.0684 7.69207 30.5155 5.99312 30.5155C5.4823 30.5277 4.9906 30.7266 4.59907 31.0795C4.20755 31.4324 3.93958 31.9181 3.83934 32.4566C3.79086 32.6927 3.76619 32.9338 3.76577 33.1755V35.0664Z"
          fill="#2C2C2C"
        />
        <path
          d="M21.2033 40.4584L20.9859 39.2002H20.909C20.4912 39.7114 19.9721 40.1154 19.3898 40.3823C18.8075 40.6492 18.1769 40.7722 17.5445 40.7423C15.1433 40.7423 13.7319 38.8911 13.7319 36.8673C13.7319 33.5673 16.481 32.0036 20.6515 32.0072V31.8311C20.6515 31.1589 20.317 30.1955 18.491 30.1991C17.3515 30.2169 16.2346 30.5433 15.2436 31.1481L14.5747 28.6318C15.9901 27.8488 17.5651 27.4584 19.1565 27.4959C23.0795 27.4959 24.3202 29.9906 24.3202 32.967V37.3849C24.2985 38.413 24.3589 39.4413 24.5008 40.4584H21.2033ZM20.7585 34.466C18.8321 34.466 17.3405 34.9549 17.3405 36.4647C17.3405 37.4855 17.9626 37.9816 18.7953 37.9816C19.2131 37.9813 19.6203 37.8408 19.9602 37.5799C20.3001 37.3189 20.5558 36.9504 20.6916 36.5258C20.7428 36.3061 20.7653 36.0799 20.7585 35.8535V34.466Z"
          fill="#2C2C2C"
        />
        <path
          d="M27.1966 37.0145C28.1612 37.5862 29.2364 37.9086 30.337 37.9563C31.4439 37.9563 31.9155 37.5573 31.9155 36.9103C31.9155 36.2632 31.5409 35.9361 30.1865 35.4472C27.735 34.5809 26.812 33.1466 26.8421 31.6584C26.8421 29.2787 28.7082 27.4922 31.6346 27.4922C32.7777 27.4735 33.9105 27.7264 34.9522 28.2327L34.3168 30.9431C33.5251 30.5031 32.6524 30.2575 31.7617 30.2241C30.8687 30.2241 30.3537 30.6052 30.3537 31.2414C30.3537 31.8777 30.8219 32.1689 32.2667 32.7045C34.5041 33.5205 35.4405 34.7571 35.4539 36.6047C35.4539 38.97 33.7382 40.7135 30.3604 40.7135C29.041 40.7415 27.7339 40.4362 26.5444 39.822L27.1966 37.0145Z"
          fill="#2C2C2C"
        />
        <path
          d="M44.6875 39.4876H38.5205V5.99585H45.2327"
          stroke="#2C2C2C"
          stroke-width="2.6755"
          stroke-miterlimit="10"
        />
        <path
          d="M45.2327 22.1433H38.5205"
          stroke="#2C2C2C"
          stroke-width="2.6755"
          stroke-miterlimit="10"
        />
        <path
          d="M0 46.7419H104.244"
          stroke="#14D8B5"
          stroke-width="2.34106"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_560_2575">
          <rect width="107" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "HeaderLogo",
};
</script>

<style lang="scss" scoped></style>
