<template>
  <svg
    width="288"
    height="103"
    viewBox="0 0 288 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2526 27.7778L51.7335 85.8133C52.936 88.0677 53.1962 90.7067 52.4572 93.1526C51.7182 95.5984 50.0401 97.6519 47.7903 98.8634C45.5355 100.066 42.8962 100.326 40.45 99.5873C38.0038 98.8483 35.95 97.1703 34.7383 94.9208L4.1391 36.8853C2.93475 34.6249 2.67347 31.98 3.41221 29.5277C4.15094 27.0754 5.82973 25.0149 8.08229 23.7957C9.2065 23.1833 10.4413 22.8003 11.7148 22.6692C12.9883 22.5381 14.2751 22.6616 15.5006 23.0321C16.726 23.4026 17.8656 24.0128 18.853 24.8275C19.8405 25.6422 20.6561 26.6451 21.2526 27.7778Z"
      stroke="#14D8B5"
      stroke-width="4.29808"
      stroke-miterlimit="10"
    />
    <path
      d="M225.097 -93.1061C190.357 -127.842 134.032 -127.842 99.2912 -93.1061C64.5508 -58.3706 64.5508 -2.05314 99.2913 32.6824C134.032 67.4179 190.357 67.4179 225.097 32.6824C259.838 -2.05316 259.838 -58.3706 225.097 -93.1061Z"
      stroke="#CDCDCD"
      stroke-width="4.29808"
      stroke-miterlimit="10"
    />
    <path
      d="M251.18 -103.591C261.409 -91.1892 268.934 -76.7865 273.27 -61.3068C277.607 -45.8271 278.66 -29.612 276.361 -13.7016C274.063 2.20882 268.465 17.4633 259.925 31.0836C251.385 44.7039 240.093 56.3893 226.771 65.3904"
      stroke="#CDCDCD"
      stroke-width="4.29808"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SecondSectionBlockRightSvg",
};
</script>

<style lang="scss" scoped></style>
