<template>
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9.99999H5V18H3C2.73478 18 2.48043 18.1053 2.29289 18.2929C2.10536 18.4804 2 18.7348 2 19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H27C27.2652 20 27.5196 19.8946 27.7071 19.7071C27.8946 19.5196 28 19.2652 28 19C28 18.7348 27.8946 18.4804 27.7071 18.2929C27.5196 18.1053 27.2652 18 27 18H25V9.99999H28C28.2176 9.99977 28.4292 9.92859 28.6026 9.79725C28.7761 9.66592 28.902 9.48158 28.9612 9.27222C29.0205 9.06286 29.0098 8.83989 28.9308 8.63714C28.8519 8.43439 28.709 8.26292 28.5238 8.14874L15.5238 0.148738C15.3662 0.0518859 15.1849 0.000610352 15 0.000610352C14.8151 0.000610352 14.6338 0.0518859 14.4762 0.148738L1.47625 8.14874C1.29103 8.26292 1.14811 8.43439 1.06916 8.63714C0.990213 8.83989 0.979546 9.06286 1.03878 9.27222C1.09801 9.48158 1.22391 9.66592 1.39738 9.79725C1.57085 9.92859 1.78242 9.99977 2 9.99999ZM7 9.99999H11V18H7V9.99999ZM17 9.99999V18H13V9.99999H17ZM23 18H19V9.99999H23V18ZM15 2.17374L24.4675 7.99999H5.5325L15 2.17374ZM30 23C30 23.2652 29.8946 23.5196 29.7071 23.7071C29.5196 23.8946 29.2652 24 29 24H1C0.734784 24 0.48043 23.8946 0.292893 23.7071C0.105357 23.5196 0 23.2652 0 23C0 22.7348 0.105357 22.4804 0.292893 22.2929C0.48043 22.1053 0.734784 22 1 22H29C29.2652 22 29.5196 22.1053 29.7071 22.2929C29.8946 22.4804 30 22.7348 30 23Z"
      fill="#343330"
    />
  </svg>
</template>

<script>
export default {
  name: "CompanySvg",
};
</script>

<style lang="scss" scoped></style>
