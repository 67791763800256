<template>
  <div>
    <v-chip prepend-icon="$vuetify"> Auth </v-chip>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style lang="scss" scoped></style>
