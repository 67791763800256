<template>
  <svg
    width="627"
    height="257"
    viewBox="0 0 627 257"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-101 1.5073L236.019 259.784L602.875 259.784"
      stroke="#CDCDCD"
      stroke-width="2.83821"
      stroke-miterlimit="10"
    />
    <path
      d="M-20.0293 176.063L-20.0293 102.92C-20.0332 100.503 -19.4008 98.1281 -18.1955 96.0331C-16.9901 93.9381 -15.2544 92.1974 -13.1629 90.9861L50.2033 54.3984C52.297 53.1935 54.6703 52.5593 57.086 52.5593C59.5016 52.5593 61.875 53.1935 63.9687 54.3984L127.335 90.9861C129.426 92.1975 131.162 93.9381 132.367 96.0331C133.573 98.1281 134.205 100.503 134.201 102.92L134.201 176.063C134.205 178.48 133.573 180.855 132.367 182.95C131.162 185.045 129.426 186.786 127.335 187.997L63.9687 224.552C61.8805 225.773 59.505 226.417 57.086 226.417C54.6669 226.417 52.2914 225.773 50.2033 224.552L-13.1629 187.997C-15.2544 186.786 -16.9901 185.045 -18.1955 182.95C-19.4008 180.855 -20.0332 178.48 -20.0293 176.063Z"
      stroke="#CDCDCD"
      stroke-width="7.76819"
      stroke-miterlimit="10"
    />
    <path
      d="M139.204 165.116C139.204 160.414 137.81 155.819 135.198 151.909C132.586 148 128.873 144.954 124.53 143.155C120.186 141.355 115.407 140.885 110.796 141.802C106.185 142.719 101.949 144.983 98.6249 148.307C95.3005 151.632 93.0366 155.867 92.1194 160.478C91.2022 165.089 91.6729 169.869 93.472 174.212C95.2711 178.556 98.3179 182.268 102.227 184.88C106.136 187.492 110.732 188.886 115.433 188.886C118.556 188.891 121.649 188.279 124.535 187.086C127.421 185.892 130.043 184.142 132.251 181.934C134.459 179.725 136.21 177.103 137.403 174.217C138.596 171.331 139.208 168.238 139.204 165.116Z"
      stroke="#14D8B5"
      stroke-width="2.35417"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  name: "SecondSectionBlockLeftSvg",
};
</script>

<style lang="scss" scoped></style>
