<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM24 13C24.0026 15.5719 23.0993 18.0626 21.4488 20.035L5.96501 4.55C7.57125 3.21372 9.52486 2.36255 11.5972 2.0961C13.6696 1.82964 15.775 2.15892 17.667 3.04541C19.5591 3.93189 21.1595 5.33891 22.281 7.10181C23.4026 8.86472 23.9988 10.9106 24 13ZM2.00001 13C1.99745 10.4281 2.90069 7.93741 4.55126 5.965L20.035 21.45C18.4288 22.7863 16.4751 23.6374 14.4028 23.9039C12.3304 24.1704 10.225 23.8411 8.333 22.9546C6.44096 22.0681 4.84053 20.6611 3.71899 18.8982C2.59745 17.1353 2.0012 15.0894 2.00001 13Z"
      fill="red"
    />
  </svg>
</template>

<script>
export default {
  name: "RejectButton",
};
</script>

<style lang="scss" scoped></style>
