<template>
  <svg
    width="10"
    height="13"
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7081 11.7076L1.70806 21.7076C1.61515 21.8005 1.50485 21.8742 1.38345 21.9245C1.26206 21.9747 1.13195 22.0006 1.00056 22.0006C0.869161 22.0006 0.739053 21.9747 0.61766 21.9245C0.496267 21.8742 0.385966 21.8005 0.293056 21.7076C0.200145 21.6147 0.126445 21.5044 0.0761623 21.383C0.0258797 21.2616 0 21.1315 0 21.0001C0 20.8687 0.0258797 20.7386 0.0761623 20.6172C0.126445 20.4958 0.200145 20.3855 0.293056 20.2926L9.58681 11.0001L0.293056 1.70757C0.105415 1.51993 -1.97712e-09 1.26543 0 1.00007C1.97712e-09 0.734704 0.105415 0.480208 0.293056 0.292568C0.480696 0.104927 0.735192 -0.000488279 1.00056 -0.000488281C1.26592 -0.000488283 1.52042 0.104927 1.70806 0.292568L11.7081 10.2926C11.801 10.3854 11.8748 10.4957 11.9251 10.6171C11.9754 10.7385 12.0013 10.8687 12.0013 11.0001C12.0013 11.1315 11.9754 11.2616 11.9251 11.383C11.8748 11.5044 11.801 11.6147 11.7081 11.7076Z"
      fill="#222C2E"
    />
  </svg>
</template>

<script>
export default {
  name: "MoreIconSvg",
};
</script>

<style lang="scss" scoped></style>
