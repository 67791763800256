<template>
  <div>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6225 1H7.37622C3.45708 1 1 3.77492 1 7.70184V18.2982C1 22.2251 3.44541 25 7.37622 25H18.6212C22.5533 25 25 22.2251 25 18.2982V7.70184C25 3.77492 22.5533 1 18.6225 1Z"
        fill="white"
        stroke="#CDCDCD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1073 9.87964L9.89062 16.0963"
        stroke="#CDCDCD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1093 16.1L9.88745 9.87683"
        stroke="#CDCDCD"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>

<style lang="scss" scoped></style>
