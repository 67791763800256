<template>
  <div class="header-basket-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M12 27C12 27.3956 11.8827 27.7822 11.6629 28.1111C11.4432 28.44 11.1308 28.6964 10.7654 28.8478C10.3999 28.9991 9.99778 29.0387 9.60982 28.9616C9.22186 28.8844 8.86549 28.6939 8.58579 28.4142C8.30608 28.1345 8.1156 27.7781 8.03843 27.3902C7.96126 27.0022 8.00087 26.6001 8.15224 26.2346C8.30362 25.8692 8.55996 25.5568 8.88886 25.3371C9.21776 25.1173 9.60444 25 10 25C10.5304 25 11.0391 25.2107 11.4142 25.5858C11.7893 25.9609 12 26.4696 12 27ZM23 25C22.6044 25 22.2178 25.1173 21.8889 25.3371C21.56 25.5568 21.3036 25.8692 21.1522 26.2346C21.0009 26.6001 20.9613 27.0022 21.0384 27.3902C21.1156 27.7781 21.3061 28.1345 21.5858 28.4142C21.8655 28.6939 22.2219 28.8844 22.6098 28.9616C22.9978 29.0387 23.3999 28.9991 23.7654 28.8478C24.1308 28.6964 24.4432 28.44 24.6629 28.1111C24.8827 27.7822 25 27.3956 25 27C25 26.4696 24.7893 25.9609 24.4142 25.5858C24.0391 25.2107 23.5304 25 23 25ZM28.9562 9.29375L25.39 20.8825C25.2032 21.4968 24.8234 22.0346 24.3068 22.416C23.7903 22.7975 23.1646 23.0022 22.5225 23H10.5087C9.85747 22.9976 9.2245 22.7842 8.70463 22.3919C8.18477 21.9996 7.80598 21.4494 7.625 20.8237L3.1025 5H1C0.734784 5 0.48043 4.89464 0.292893 4.70711C0.105357 4.51957 0 4.26522 0 4C0 3.73478 0.105357 3.48043 0.292893 3.29289C0.48043 3.10536 0.734784 3 1 3H3.1025C3.53676 3.00144 3.95888 3.14349 4.30567 3.40487C4.65246 3.66626 4.90526 4.03293 5.02625 4.45L6.04 8H28C28.1565 7.99994 28.3108 8.03661 28.4505 8.10706C28.5903 8.17751 28.7115 8.27976 28.8045 8.40561C28.8976 8.53145 28.9597 8.67737 28.9861 8.83163C29.0124 8.98588 29.0022 9.14417 28.9562 9.29375ZM26.6462 10H6.61125L9.5475 20.275C9.60724 20.4839 9.73336 20.6676 9.90679 20.7984C10.0802 20.9292 10.2915 21 10.5087 21H22.5225C22.7365 21.0001 22.9448 20.9315 23.117 20.8044C23.2891 20.6773 23.4159 20.4983 23.4788 20.2938L26.6462 10Z"
        fill="#2C2C2C"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "HeaderBasketIcon",
};
</script>

<style lang="scss" scoped></style>
