<template>
  <div>
    <svg
      width="435"
      height="228"
      viewBox="0 0 435 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M267.722 -26H174.113C171.018 -25.9978 167.978 -25.1805 165.3 -23.6303C162.621 -22.0802 160.398 -19.8519 158.854 -17.1697L111.913 63.9687C110.371 66.6488 109.559 69.6871 109.559 72.7796C109.559 75.8721 110.371 78.9104 111.913 81.5906L158.699 162.613C160.243 165.295 162.466 167.523 165.145 169.073C167.824 170.624 170.863 171.441 173.958 171.443H267.567C270.662 171.441 273.702 170.624 276.381 169.073C279.06 167.523 281.283 165.295 282.827 162.613L329.612 81.5906C331.17 78.9156 331.991 75.8753 331.991 72.7796C331.991 69.6839 331.17 66.6436 329.612 63.9687L282.827 -17.0922C281.306 -19.7663 279.111 -21.9955 276.461 -23.5583C273.811 -25.1211 270.799 -25.9629 267.722 -26Z"
        stroke="#CDCDCD"
        stroke-width="7.7459"
        stroke-miterlimit="10"
      />
      <path
        d="M332.131 72.6829H367.607"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M376.631 81.6678C374.854 81.6678 373.116 81.1408 371.639 80.1535C370.161 79.1662 369.01 77.7629 368.329 76.1211C367.649 74.4792 367.471 72.6726 367.818 70.9296C368.165 69.1866 369.021 67.5856 370.277 66.329C371.534 65.0724 373.135 64.2166 374.878 63.8699C376.621 63.5232 378.427 63.7012 380.069 64.3812C381.711 65.0613 383.114 66.213 384.102 67.6906C385.089 69.1682 385.616 70.9054 385.616 72.6825C385.616 75.0656 384.669 77.351 382.984 79.036C381.299 80.7211 379.014 81.6678 376.631 81.6678Z"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M431.542 124.49C433.452 119.91 431.287 114.648 426.707 112.738C422.127 110.828 416.866 112.992 414.956 117.572C413.046 122.152 415.21 127.413 419.79 129.324C424.37 131.234 429.631 129.07 431.542 124.49Z"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M108.196 72.6829H72.7197"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M63.7339 63.6973C65.5093 63.705 67.2427 64.2385 68.7153 65.2304C70.1878 66.2224 71.3334 67.6284 72.0075 69.2709C72.6817 70.9134 72.854 72.7188 72.503 74.4592C72.1519 76.1996 71.2931 77.797 70.035 79.0497C68.7769 80.3025 67.1758 81.1544 65.4339 81.4979C63.692 81.8415 61.8874 81.6613 60.2478 80.9801C58.6082 80.2989 57.2071 79.1473 56.2215 77.6705C55.2359 76.1937 54.71 74.4581 54.71 72.6826C54.7099 71.4994 54.9436 70.3278 55.3976 69.2352C55.8516 68.1425 56.5169 67.1503 57.3554 66.3154C58.1938 65.4805 59.1889 64.8194 60.2835 64.3702C61.3781 63.9209 62.5507 63.6922 63.7339 63.6973Z"
        stroke="#14D8B5"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M55.446 72.6829H19.9697"
        stroke="#14D8B5"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M10.9853 63.6973C12.7624 63.6973 14.4995 64.2242 15.9772 65.2116C17.4548 66.1989 18.6065 67.6022 19.2866 69.244C19.9666 70.8858 20.1446 72.6925 19.7979 74.4355C19.4512 76.1784 18.5954 77.7795 17.3387 79.0361C16.0821 80.2927 14.4812 81.1484 12.7382 81.4951C10.9952 81.8418 9.18864 81.6639 7.5468 80.9838C5.90496 80.3038 4.5016 79.1521 3.51429 77.6745C2.52698 76.1969 2 74.4597 2 72.6825C2 70.2995 2.94663 68.0141 4.63169 66.329C6.31675 64.644 8.60224 63.6973 10.9853 63.6973Z"
        stroke="#14D8B5"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M273.687 170.963L296.327 209.169"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M292.548 220.835C291.325 218.78 290.969 216.325 291.558 214.008C292.146 211.691 293.63 209.702 295.685 208.48C297.739 207.258 300.195 206.902 302.512 207.49C304.829 208.078 306.817 209.563 308.04 211.617C308.645 212.634 309.044 213.761 309.214 214.932C309.383 216.104 309.321 217.297 309.03 218.444C308.738 219.591 308.224 220.67 307.516 221.619C306.808 222.567 305.92 223.367 304.902 223.972C303.885 224.577 302.759 224.976 301.587 225.146C300.416 225.316 299.223 225.253 298.075 224.962C296.928 224.671 295.849 224.156 294.901 223.448C293.953 222.74 293.153 221.852 292.548 220.835Z"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M383.796 78.0276L417.219 115.828"
        stroke="#CDCDCD"
        stroke-width="3.87295"
        stroke-miterlimit="10"
      />
      <path
        d="M237.669 65.8246H224.772V52.9277H212.418V65.8246H199.521V78.1793H212.418V91.0763H224.772V78.1793H237.669V65.8246Z"
        fill="#14D8B5"
      />
      <path
        d="M74.0749 106.762H66.755V99.4031H59.6675V106.762H52.3477V113.81H59.6675V121.13H66.755V113.81H74.0749V106.762Z"
        fill="#14D8B5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "SecondBlockLeftSvg",
};
</script>

<style lang="scss" scoped></style>
