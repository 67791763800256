<template>
  <svg
    width="203"
    height="17"
    viewBox="0 0 203 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L22.5 16.5H203" stroke="#CDCDCD" />
  </svg>
</template>

<script>
export default {
  name: "CatalogItemPropertiesSvg",
};
</script>

<style lang="scss" scoped></style>
