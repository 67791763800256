<template>
  <div class="language-button">
    <img src="/icons/ArrowDown.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "HeaderLangButton",
};
</script>

<style lang="scss" scoped>
.language-button {
  align-items: center;
}
</style>
