<template>
  <div>
    <svg
      width="289"
      height="228"
      viewBox="0 0 289 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M241.836 10.859L241.836 105.328C241.834 108.452 241.009 111.519 239.443 114.223C237.878 116.926 235.628 119.17 232.919 120.728L150.979 168.099C148.272 169.656 145.204 170.476 142.081 170.476C138.957 170.476 135.889 169.656 133.182 168.099L51.3596 120.884C48.6508 119.326 46.4005 117.082 44.8351 114.379C43.2696 111.676 42.4442 108.608 42.442 105.485L42.442 11.0154C42.4442 7.89203 43.2696 4.82433 44.8351 2.12099C46.4005 -0.582359 48.6508 -2.82601 51.3596 -4.38425L133.182 -51.5993C135.884 -53.1717 138.954 -54.0001 142.081 -54.0001C145.207 -54.0001 148.277 -53.1717 150.979 -51.5993L232.841 -4.38425C235.541 -2.8492 237.792 -0.634368 239.371 2.03988C240.949 4.71412 241.799 7.75457 241.836 10.859Z"
        stroke="#CDCDCD"
        stroke-width="8.5741"
        stroke-miterlimit="10"
      />
      <path
        d="M142.178 171.852L142.178 207.654"
        stroke="#CDCDCD"
        stroke-width="2.85803"
        stroke-miterlimit="10"
      />
      <path
        d="M151.253 216.722C151.245 214.93 150.706 213.18 149.705 211.694C148.703 210.208 147.283 209.052 145.624 208.372C143.966 207.692 142.142 207.518 140.385 207.872C138.627 208.226 137.014 209.093 135.749 210.363C134.484 211.632 133.623 213.248 133.276 215.006C132.929 216.764 133.111 218.585 133.799 220.24C134.487 221.894 135.65 223.308 137.142 224.303C138.633 225.298 140.386 225.828 142.179 225.828C143.374 225.828 144.557 225.593 145.66 225.134C146.764 224.676 147.766 224.005 148.609 223.159C149.452 222.312 150.12 221.308 150.573 220.204C151.027 219.099 151.258 217.916 151.253 216.722Z"
        stroke="#14D8B5"
        stroke-width="2.85803"
        stroke-miterlimit="10"
      />
      <path
        d="M239.294 116.703L270.076 135.034"
        stroke="#CDCDCD"
        stroke-width="2.85803"
        stroke-miterlimit="10"
      />
      <path
        d="M282.552 131.867C280.477 130.633 277.997 130.274 275.657 130.867C273.318 131.461 271.31 132.959 270.075 135.033C268.841 137.106 268.481 139.584 269.075 141.922C269.669 144.261 271.169 146.267 273.243 147.501C274.27 148.112 275.408 148.514 276.591 148.686C277.774 148.857 278.979 148.794 280.138 148.5C281.296 148.206 282.386 147.687 283.343 146.972C284.301 146.258 285.109 145.361 285.72 144.335C286.331 143.308 286.734 142.172 286.906 140.989C287.077 139.807 287.014 138.603 286.72 137.445C286.426 136.287 285.906 135.199 285.191 134.242C284.476 133.284 283.579 132.477 282.552 131.867Z"
        stroke="#CDCDCD"
        stroke-width="2.85803"
        stroke-miterlimit="10"
      />
      <path
        d="M59.2297 219.145V209.149C59.2289 208.819 59.1414 208.494 58.9759 208.209C58.8104 207.923 58.5727 207.686 58.2866 207.521L49.7125 202.551C49.4294 202.383 49.106 202.294 48.7765 202.294C48.447 202.294 48.1236 202.383 47.8405 202.551L39.2664 207.521C38.9803 207.686 38.7426 207.923 38.5771 208.209C38.4115 208.494 38.324 208.819 38.3233 209.149V219.145C38.3222 219.473 38.409 219.796 38.5748 220.08C38.7407 220.364 38.9794 220.598 39.2664 220.759L47.8405 225.742C48.1236 225.911 48.447 226 48.7765 226C49.106 226 49.4294 225.911 49.7125 225.742L58.2866 220.759C58.5736 220.598 58.8123 220.364 58.9782 220.08C59.144 219.796 59.2308 219.473 59.2297 219.145Z"
        stroke="#14D8B5"
        stroke-width="2.85803"
        stroke-miterlimit="10"
      />
      <path
        d="M27.1628 202.908L6.14194 213.947C5.32482 214.382 4.36831 214.476 3.48181 214.209C2.5953 213.941 1.85103 213.333 1.4119 212.519C0.976088 211.702 0.881799 210.746 1.14962 209.86C1.41745 208.974 2.02557 208.231 2.84091 207.792L23.9046 196.639C24.7239 196.203 25.6825 196.108 26.5714 196.376C27.4602 196.643 28.207 197.251 28.649 198.067C28.8788 198.48 29.0227 198.936 29.0722 199.406C29.1217 199.876 29.0756 200.351 28.9369 200.803C28.7981 201.256 28.5695 201.675 28.2646 202.037C27.9598 202.398 27.585 202.695 27.1628 202.908Z"
        fill="#CDCDCD"
        stroke="#CDCDCD"
        stroke-width="1.42902"
        stroke-miterlimit="10"
      />
      <path
        d="M6.09962 155.483V187.614C6.10156 188.676 6.38238 189.719 6.914 190.639C7.44562 191.559 8.20943 192.323 9.12913 192.855L36.9664 208.92C37.887 209.453 38.932 209.734 39.9959 209.734C41.0598 209.734 42.1048 209.453 43.0254 208.92L70.8627 192.855C71.7824 192.323 72.5463 191.559 73.0779 190.639C73.6095 189.719 73.8903 188.676 73.8922 187.614V155.483C73.8932 154.421 73.6136 153.377 73.0817 152.456C72.5498 151.536 71.7844 150.773 70.8627 150.243L43.0254 134.177C42.1048 133.644 41.0598 133.364 39.9959 133.364C38.932 133.364 37.887 133.644 36.9664 134.177L9.11487 150.243C8.19588 150.774 7.4333 151.539 6.90397 152.459C6.37464 153.379 6.09722 154.422 6.09962 155.483Z"
        stroke="#CDCDCD"
        stroke-width="2.85803"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "FirstBlockSvg",
};
</script>

<style lang="scss" scoped></style>
